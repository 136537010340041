<template>
    <div>
        Esta seguro que desea eliminar el registro # {{rc.id}} de {{rc.p_nombre}} {{rc.p_apellido}}?   
    </div>
</template>
<script>

export default {
  name: 'rc_del_form',
  components: {},
  props: {
    rc: { type: Object }
  },
  data(){
    return {
    }
  }
}
</script>