<template>
    <div>
        <b-overlay :show="show_overlay" rounded="sm">
            <b-form inline>
                <label class="sr-only" for="f_nacimiento">Fecha Nacimiento</label>
                <b-input-group prepend="F. Nac" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="f_nacimiento" type="text" v-model="persona.f_nacimiento"></b-form-input>
                    <b-input-group-append>
                    <b-form-datepicker id="f_nacimiento" v-model="persona.f_nacimiento" button-only right @context="onContext"></b-form-datepicker>
                    </b-input-group-append>
                </b-input-group>

                <label class="sr-only" for="num_cedula"># Cédula</label>
                <b-input-group prepend="Cédula" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="num_cedula" type="number" v-model="persona.num_cedula"></b-form-input>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
                <label class="sr-only" for="p_nombre">1er Nombre</label>
                <b-input-group prepend="1er Nombre" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="p_nombre" v-model="persona.p_nombre"></b-form-input>
                </b-input-group>

                <label class="sr-only" for="s_nombre">2do Nombre</label>
                <b-input-group prepend="2do Nombre" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="s_nombre" v-model="persona.s_nombre"></b-form-input>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
                <label class="sr-only" for="p_apellido">1er Apellido</label>
                <b-input-group prepend="1er Apellido" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="p_apellido" v-model="persona.p_apellido"></b-form-input>
                </b-input-group>

                <label class="sr-only" for="s_apellido">2do Apellido</label>
                <b-input-group prepend="2do Apellido" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="s_apellido" v-model="persona.s_apellido"></b-form-input>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
    
                <label class="sr-only" for="telf">Telf.</label>
                <b-input-group prepend="Telefono" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="telf" v-model="persona.phone"></b-form-input>
                </b-input-group>

                <label class="sr-only" for="email">Email</label>
                <b-input-group prepend="Email" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="email" v-model="persona.email"></b-form-input>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
                <label class="sr-only" for="sex">Sexo</label>
                <b-input-group prepend="Sexo" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-select id="sex" v-model="persona.sexo" :options="opts_sexo"/>
                </b-input-group>

                <label class="sr-only" for="estado_civil">Estado Civil</label>
                <b-input-group prepend="Est. Civil" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-select id="estado_civil" v-model="persona.estado_civil" :options="opts_estado_civil"/>
                </b-input-group>

                <label class="sr-only" for="tipo_visa">Visa</label>
                    <b-input-group prepend="Visa" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-select id="tipo_visa" v-model="persona.visa_tipo" :options="opts_visa"/>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
                <label class="sr-only" for="nivel_educativo">Nivel Educativo</label>
                <b-input-group prepend="Nivel Educativo" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-select id="nivel_educativo" v-model="persona.nivel_educativo" :options="opts_n_educativo"/>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
                <label class="sr-only" for="profesion">Profesion</label>
                <b-input-group prepend="Profesión" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="profesion" v-model="persona.profesion"></b-form-input>
                </b-input-group>

                <label class="sr-only" for="ocupacion">Ocupacion</label>
                <b-input-group prepend="Ocupación" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="ocupacion" v-model="persona.ocupacion"></b-form-input>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">    
                <label class="sr-only" for="dir_pais">Pais</label>
                <b-input-group prepend="Pais" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-select id="dir_pais" v-model="persona.dir_pais" :options="opts_country"/>
                </b-input-group>

                <label class="sr-only" for="dir_calle">Calle</label>
                <b-input-group prepend="Unit / Calle" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="dir_calle" v-model="persona.dir_calle"></b-form-input>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
                <label class="sr-only" for="dir_estado">Estado</label>
                <b-input-group prepend="Estado" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-select id="dir_estado" v-model="persona.dir_estado" :options="opts_states"/>
                </b-input-group>

                <label class="sr-only" for="dir_suburbio">Calle</label>
                <b-input-group prepend="Suburbio" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="dir_suburbio" v-model="persona.dir_suburbio"></b-form-input>
                </b-input-group>
            </b-form>

            <b-form inline class="mt-2">
                <label class="sr-only" for="dir_zipcode">Calle</label>
                <b-input-group prepend="C. Postal" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-input id="dir_zipcode" v-model="persona.dir_zipcode"></b-form-input>
                </b-input-group>
            </b-form>

            <b-container fluid class="mt-2 p-4">
                <b-row>
                    <b-col>
                        <h4>PDN</h4>
                        <div v-if="persona.pdn_mime">
                            <a target="_blank" :href="'./images_temp/'+persona.id+'_pdn.'+persona.pdn_ext">
                                <b-img thumbnail fluid center :src="'./images_temp/'+persona.id+'_pdn.'+persona.pdn_ext" alt="PDN" width="180" height="180"></b-img>
                            </a>
                        </div>
                        <b-form-file @input="calcSize(1)" v-model="files.pdn" accept="image/jpeg, image/png, application/pdf" placeholder="Arrastrar archivo" browse-text="Buscar" class="mt-3" />
                    </b-col>
                    <b-col>
                        <h4>Pasaporte</h4>
                        <div v-if="persona.pasaporte_mime">
                            <a target="_blank" :href="'./images_temp/'+persona.id+'_pasaporte.'+persona.pasaporte_ext">
                                <b-img thumbnail fluid center :src="'./images_temp/'+persona.id+'_pasaporte.'+persona.pasaporte_ext" alt="PASAPORTE" width="180" height="180"></b-img>
                            </a>
                        </div>
                        <b-form-file @input="calcSize(3)" v-model="files.pasaporte" accept="image/jpeg, image/png, application/pdf" placeholder="Arrastrar archivo" browse-text="Buscar" class="mt-3" />
                    </b-col>
                    <b-col>
                        <h4>Cédula</h4>
                        <div v-if="persona.cedula_mime">
                            <a target="_blank" :href="'./images_temp/'+persona.id+'_cedula.'+persona.cedula_ext">
                                <b-img thumbnail fluid center :src="'./images_temp/'+persona.id+'_cedula.'+persona.cedula_ext" alt="CEDULA" width="180" height="180"></b-img>
                            </a>
                        </div>
                        <b-form-file @input="calcSize(2)" v-model="files.cedula" accept="image/jpeg, image/png, application/pdf" placeholder="Arrastrar archivo" browse-text="Buscar" class="mt-3" />
                    </b-col>
                </b-row>
            </b-container>

            <!-- <b-container fluid class="mt-2">
            </b-container> -->

            <b-container>
                <h4>Contacto de Emergencia</h4>
                <b-form inline class="mt-2">
                    <label class="sr-only" for="p_nombre_c">1er Nombre</label>
                    <b-input-group prepend="1er Nombre" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="p_nombre_c" v-model="persona.contacts.p_nombre"></b-form-input>
                    </b-input-group>

                    <label class="sr-only" for="s_nombre_c">2do Nombre</label>
                    <b-input-group prepend="2do Nombre" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="s_nombre_c" v-model="persona.contacts.s_nombre"></b-form-input>
                    </b-input-group>
                </b-form>

                <b-form inline class="mt-2">
                    <label class="sr-only" for="p_apellido_c">1er Apellido</label>
                    <b-input-group prepend="1er Apellido" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="p_apellido_c" v-model="persona.contacts.p_apellido"></b-form-input>
                    </b-input-group>

                    <label class="sr-only" for="s_apellido_c">2do Apellido</label>
                    <b-input-group prepend="2do Apellido" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="s_apellido_c" v-model="persona.contacts.s_apellido"></b-form-input>
                    </b-input-group>
                </b-form>

                <b-form inline class="mt-2">
                    <label class="sr-only" for="telf_c">Telf.</label>
                    <b-input-group prepend="Telefono" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="telf_c" v-model="persona.contacts.phone"></b-form-input>
                    </b-input-group>

                    <label class="sr-only" for="email_c">Email</label>
                    <b-input-group prepend="Email" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="email_c" v-model="persona.contacts.email"></b-form-input>
                    </b-input-group>
                </b-form>
        
                <b-form inline class="mt-2">
        
                    <label class="sr-only" for="vinculo">Vinculo</label>
                    <b-input-group prepend="Vinculo" class="mb-2 mr-sm-2 mb-sm-0">
                        <b-form-input id="vinculo" v-model="persona.contacts.vinculo"></b-form-input>
                    </b-input-group>

                </b-form>

            </b-container>

            <b-form inline class="mt-2">
                <label class="sr-only" for="estatus">Estatus</label>
                <b-input-group prepend="Estatus" class="mb-2 mr-sm-2 mb-sm-0">
                    <b-form-select id="estatus" v-model="persona.estatus" :options="opts_estatus"/>
                </b-input-group>
            </b-form>
        
            <!-- <b-row align-h="end" class="mr-1">
                <b-button size="sm" variant="success" @click="onSubmit(persona)">Guardar</b-button>
            </b-row> -->
        </b-overlay>
    </div>
</template>
<script>
export default {
  name: 'rc_mod_form',
  components: {},
  props: {
    rcdata: Object
  },
  emits: ['saverc'],
  data(){
    return {
        show_overlay: true,
        // persona: {},
        opts_sexo: [
            { value: 0, text: 'Femenino'},
            { value: 1, text: 'Masculino'}
        ],
        opts_estatus: [{value: 0, text: 'Por Revisar'}, {value: 1, text: 'Completo'}, {value: 2, text: 'Por Actualizar'}],
        opts_country: [],
        opts_visa: [],
        opts_n_educativo: [],
        opts_states: [],
        opts_estado_civil: [],
        
        size_pdn: 0,
        size_cedula: 0,
        size_pasaporte: 0,

        files: {
            pdn: null,
            cedula: null,
            pasporte: null
        },
    }
  },
  methods: {
    onContext(ctx) {
      // const datedob = Date.parse(ctx.activeDate);
      const datedob = Date.parse(ctx.selectedFormatted);
      const datetoday = Date.now();
      // console.log('DOB:',datedob);
      // console.log('TODAY',datetoday);
      // The date formatted in the locale, or the `label-no-date-selected` string
      // this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      // this.selected = ctx.selectedYMD
      this.edad = parseInt((datetoday-datedob)/31536036000);
      },
    calcSize(type){
      switch(type){
        case 1:
          this.size_pdn = (this.files.pdn.size/Math.pow(1024,2)).toFixed(3);
          if(this.size_pdn > 2){ alert("Límite máximo sobrepasado!")}
          break;
        case 2:
          this.size_cedula = (this.files.cedula.size/Math.pow(1024,2)).toFixed(3);
          if(this.size_cedula > 2){ alert("Límite máximo sobrepasado!")}
          break;
        case 3:
          this.size_pasaporte = (this.files.pasaporte.size/Math.pow(1024,2)).toFixed(3);
          if(this.size_pasaporte > 2){ alert("Límite máximo sobrepasado!")}
          break
      }
    },
    async onSubmit(){
      // event.preventDefault();
      if(
        this.size_pdn > 2 || this.size_cedula > 2 || this.size_pasaporte > 2){
        alert('Alguno de los archivos suministrados supera el tamaño máximo permitido de 2 MB!')
      } else {
          this.persona.update = true;
          const formData = new FormData();
          formData.append('ID', JSON.stringify(this.$store.state.id));
          formData.append('RC', JSON.stringify(this.persona));
          if(this.files.pdn){ formData.append('upFiles',this.files.pdn, 'pdn'); }
          if(this.files.cedula){ formData.append('upFiles',this.files.cedula, 'cedula'); }
          if(this.files.pasaporte){ formData.append('upFiles',this.files.pasaporte, 'pasaporte'); }
          await fetch('/api/uploadrc/',{ method: 'POST', mode: 'cors', body: formData });
          this.$emit('refresh');
        //   this.$bvModal.show('submitted');
        // console.log(this.persona)
      }
    },
    async get_estados(id){
      const estados = await fetch(`/api/getestados/${id}`).then(res => res.json());
      this.opts_states = estados;
    },
    update_status(persona){
      const obj_update = {
        id: persona.id,
        estatus: persona.estatus,
        p_nombre: persona.p_nombre,
        p_apellido: persona.p_apellido,
        email: persona.email
      }
      fetch('/api/update_status',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({uid: this.$store.state.id, persona: obj_update})});
    },
    edit_rc(data){
    //   console.log(data);
      const persona = {
        id: data.id,
        p_nombre: data.p_nombre,
        p_apellido: data.p_apellido,
        email: data.email,
        estatus: 2,
        otp: data.otp,
        msg: data.msg
      }
      fetch('/api/edit_rc', { method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({uid: this.$store.state.id,persona})});
    },
    async get_data(id){
        const vartemp = await fetch('/api/get_registro', { method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({id})})
        .then(res => res.json());
        this.persona = {...this.persona, ...vartemp};
        this.get_estados(this.persona.dir_pais);
        this.show_overlay = false;
    }
  },
  computed:{

  },
  beforeMount(){
        this.persona = {...this.rcdata, contacts: {}};
        // console.log(this.persona)
  },
  mounted(){
    fetch('/api/getopts').then(res => res.json()).then( opciones => {
      this.opts_country = opciones.paises;
      this.opts_estado_civil = opciones.estado_civil;
      this.opts_n_educativo = opciones.nivel_educativo;
      this.opts_visa = opciones.tipo_de_visa;
    });
    this.get_data(this.persona.id);
  }
}
</script>