<template>
<div>
  <box>
    <h1>Por Revisar</h1>
    <b-pagination v-model="por_revisar_currentPage" :total-rows="por_revisar_rows" :per-page="perPage" aria-controls="por-revisar" />
    <b-table id="por-revisar" :per-page="perPage" :current-page="por_revisar_currentPage" hover striped header-variant="dark" :items="reg_data" :fields="reg_data_fields" primary-key="id" sort-by="Hora" :sortDesc="false" small responsive>
      <template #cell(Acciones)="data">
        <b-img src="../assets/edit-icon.png" alt="Editar" width="25px" @click="$bvModal.show('mod'+data.item.id)"></b-img>
        <b-img src="../assets/del-icon.png" alt="Borrar" width="25px" @click="$bvModal.show('del'+data.item.id)"></b-img>
        <b-img src="../assets/email-icon.png" alt="Enviar Mensaje" width="25px" @click="$bvModal.show('edit'+data.item.id)"></b-img>

        <b-modal :id="'edit'+data.item.id" title="Enviar mensaje">
          Este mensaje será incluido junto con el procedimiento de como hacer la corrección:
          <b-form-textarea id="edit_msg" v-model="data.item.msg" placeholder="Escriba lo que desea que la persona corrija.."/>
          
          <template #modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="success" @click="ok(edit_rc(data.item))">Enviar</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">Cancelar</b-button>
          </template>

        </b-modal>

        <b-modal :id="'del'+data.item.id" title="Borrar Registro">
          <rc_del_form :rc="{id: data.item.id, p_nombre: data.item.p_nombre, p_apellido: data.item.p_apellido}"/>
          <template #modal-footer="{ ok,cancel }">
            <b-button size="sm" variant="success" @click="ok(del_rc(data.item.id,0,data.index))">Si</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">No</b-button>
          </template>
        </b-modal>

        <b-modal :id="'mod'+data.item.id" size="lg" title="Modificar Registro" >
          <rc_mod_form ref="mod_pr_child" :rcdata="data.item" @refresh="get_registros_list(0);get_registros_list(2)"/>
          <template #modal-footer="{ ok,cancel }">
            <b-button size="sm" variant="success" @click="ok($refs.mod_pr_child.onSubmit())">Guardar</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">Cerrar</b-button>
          </template>
        </b-modal>
      </template>
     
      <!-- <template #cell(estatus)="data">
        <b-form-select id="estatus1" v-model="data.item.estatus" :options="opts_estatus" @change="update_status(data.item)"/>
      </template> -->
    </b-table>
  </box>
  <box>
    <h1>Por Actualizar</h1>
    <b-pagination v-model="por_actualizar_currentPage" :total-rows="por_actualizar_rows" :per-page="perPage" aria-controls="por-actualizar" />
      <b-table id="por-actualizar" :per-page="perPage" :current-page="por_actualizar_currentPage" hover striped header-variant="dark" :items="reg_tramite" :fields="reg_data_fields" primary-key="id" sort-by="Hora" :sortDesc="false" small responsive>
      <template #cell(Acciones)="data">
        <b-img src="../assets/edit-icon.png" alt="Editar" width="25px" @click="$bvModal.show('mod'+data.item.id)"></b-img>
        <b-img src="../assets/del-icon.png" alt="Borrar" width="25px" @click="$bvModal.show('del'+data.item.id)"></b-img>
        <b-img src="../assets/email-icon.png" alt="Enviar Mensaje" width="25px" @click="$bvModal.show('edit'+data.item.id)"></b-img>

        <b-modal :id="'mod'+data.item.id" size="lg" title="Modificar Registro" >
          <rc_mod_form ref="mod_pa_child" :rcdata="data.item" @refresh="get_registros_list(2);get_registros_list(0)"/>
          <template #modal-footer="{ ok,cancel }">
            <b-button size="sm" variant="success" @click="ok($refs.mod_pa_child.onSubmit())">Guardar</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">Cerrar</b-button>
          </template>
        </b-modal>
      
        <b-modal :id="'edit'+data.item.id" title="Enviar mensaje">
          Este mensaje será incluido junto con el procedimiento de como hacer la corrección:
          <b-form-textarea id="edit_msg" v-model="data.item.msg" placeholder="Escriba lo que desea que la persona corrija.."/>
          
          <template #modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="success" @click="ok(edit_rc(data.item))">Enviar</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">Cancelar</b-button>
          </template>

        </b-modal>

        <b-modal :id="'del'+data.item.id" title="Borrar Registro">
          <rc_del_form :rc="{id: data.item.id, p_nombre: data.item.p_nombre, p_apellido: data.item.p_apellido}"/>
          <template #modal-footer="{ ok,cancel }">
            <b-button size="sm" variant="success" @click="ok(del_rc(data.item.id,1,data.index))">Si</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">No</b-button>
          </template>
        </b-modal>
      </template>

      <!-- <template #cell(estatus)="data">
        <b-form-select id="estatus1" v-model="data.item.estatus" :options="opts_estatus" @change="update_status(data.item)"/>
      </template> -->
    </b-table>
  </box>
  <box>
    <h1>Buscar</h1>

    <b-form inline class="mb-2" @submit="get_buscar">
      <label class="sr-only" for="filedsopts">Campo</label>
        <b-input-group prepend="Campo" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-select id="Campo" v-model="query.field" :options="fieldOpts"/>
        </b-input-group>

      <label class="sr-only" for="texto">Texto</label>
        <b-input-group prepend="Texto" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-input id="texto" v-model="query.qstring"></b-form-input>
        </b-input-group>

      <label class="sr-only" for="similitud">Similitud</label>
        <b-input-group prepend="Alcance" class="mb-2 mr-sm-2 mb-sm-0">
          <b-form-select id="similitud" v-model="query.context" :options="queryOpts"/>
        </b-input-group>

      <b-button variant="primary" type="submit">Buscar</b-button>
    </b-form>

    <b-pagination v-model="por_buscar_currentPage" :total-rows="por_buscar_rows" :per-page="perPage" aria-controls="por-buscar" />
      <b-table id="por-buscar" :per-page="perPage" :current-page="por_buscar_currentPage" hover striped header-variant="dark"
        :items="reg_buscar" :fields="reg_data_fields" primary-key="id" sort-by="Hora" :sortDesc="false" small responsive>
     
      <template #cell(Acciones)="data">
        <b-img src="../assets/edit-icon.png" alt="Editar" width="25px" @click="$bvModal.show('mod'+data.item.id)"></b-img>
        <b-img src="../assets/del-icon.png" alt="Borrar" width="25px" @click="$bvModal.show('del'+data.item.id)"></b-img>
        <b-img src="../assets/email-icon.png" alt="Enviar Mensaje" width="25px" @click="$bvModal.show('edit'+data.item.id)"></b-img>

        <b-modal :id="'edit'+data.item.id" title="Enviar mensaje">
          Este mensaje será incluido junto con el procedimiento de como hacer la corrección:
          <b-form-textarea id="edit_msg" v-model="data.item.msg" placeholder="Escriba lo que desea que la persona corrija.."/>
          <template #modal-footer="{ ok, cancel }">
            <b-button size="sm" variant="success" @click="ok(edit_rc(data.item))">Enviar</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">Cancelar</b-button>
          </template>
        </b-modal>

        <b-modal :id="'del'+data.item.id" title="Borrar Registro">
          <rc_del_form :rc="{id: data.item.id, p_nombre: data.item.p_nombre, p_apellido: data.item.p_apellido}"/>
          <template #modal-footer="{ ok,cancel }">
            <b-button size="sm" variant="success" @click="ok(del_rc(data.item.id))">Si</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">No</b-button>
          </template>
        </b-modal>

        <b-modal :id="'mod'+data.item.id" size="lg" title="Modificar Registro" >
          <rc_mod_form ref="mod_bus_child" :rcdata="data.item"/>
          <template #modal-footer="{ ok,cancel }">
            <b-button size="sm" variant="success" @click="ok($refs.mod_bus_child.onSubmit())">Guardar</b-button>
            <b-button size="sm" variant="danger" @click="cancel()">Cerrar</b-button>
          </template>
        </b-modal>

      </template>

      <!-- <template #cell(estatus)="data">
        <b-form-select id="estatus1" v-model="data.item.estatus" :options="opts_estatus" @change="update_status(data.item)"/>
      </template> -->
    </b-table>
  </box>
</div>
</template>
<script>
import box from '../components/parts/box.vue';
import rc_mod_form from '../components/parts/rc_mod_form.vue';
import rc_del_form from '../components/parts/rc_del_form.vue';

export default {
  name: 'registro_consular',
  components: {
    box,
    rc_mod_form,
    rc_del_form
  },
  data(){
    return {
      dataitemcontacts: {},
      rc:{},
      reg_data:[],
      reg_data_fields: [
        'id', {key: 'f_nacimiento', label: 'Fecha de Nac.'},{key: 'p_nombre', label: '1er Nombre'}, {key: 's_nombre', label: '2do Nombre'},{key:'p_apellido', label:'1er Apellido'},{key:'s_apellido',label:'2do Apellido'},{key:'num_cedula',label:'Cédula'},'phone','email','estado','Acciones'
      ],
      reg_tramite: [],
      // reg_tramite_fields: [],
      reg_buscar: [],
      // reg_buscar_fields: [],
      opts_sexo: [
        { value: 0, text: 'Femenino'},
        { value: 1, text: 'Masculino'}
      ],
      por_actualizar_currentPage: 1,
      por_revisar_currentPage: 1,
      por_buscar_currentPage: 1,
      perPage: 10,
      opts_country: [],
      opts_visa: [],
      opts_n_educativo: [],
      opts_states: [],
      opts_estado_civil: [],
      size_pdn: 0,
      size_cedula: 0,
      size_pasaporte: 0,
      query: {
        field: 'p_nombre',
        qstring: undefined,
        context: '='
      },
      fieldOpts: [ {value: 'p_nombre', text: 'Primer Nombre'}, {value: 'p_apellido', text: 'Primer Apellido'}, {value: 'num_cedula', text: 'Cedula'}, {value: 'persona.id', text: '# de registro'} ],
      queryOpts: [ {value: '=', text: 'Igual'}, {value: 'LIKE', text: 'Contenga'} ],
      
      images: {
        pdn:null,
        pasaporte: null,
        cedula: null
      },
      files: {
        pdn: null,
        cedula: null,
        pasporte: null
      },
    }
  },
  computed: {
    por_actualizar_rows(){
      return this.reg_tramite.length;
    },
    por_revisar_rows(){
      return this.reg_data.length;
    },
    por_buscar_rows(){
      return this.reg_buscar.length;
    }
  },
  methods: {
    onContext(ctx) {
      // const datedob = Date.parse(ctx.activeDate);
      const datedob = Date.parse(ctx.selectedFormatted);
      const datetoday = Date.now();
      // console.log('DOB:',datedob);
      // console.log('TODAY',datetoday);
      // The date formatted in the locale, or the `label-no-date-selected` string
      // this.formatted = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      // this.selected = ctx.selectedYMD
      this.edad = parseInt((datetoday-datedob)/31536036000);
      },
    //   rowColor({status}){
    //   switch(status){
    //     case 1:
    //      return 'table-success';
    //     case 2:
    //       return 'table-warning';
    //     default:
    //       return 'table-danger';
    //   }
    // },
    calcSize(type){
      switch(type){
        case 1:
          this.size_pdn = (this.files.pdn.size/Math.pow(1024,2)).toFixed(3);
          if(this.size_pdn > 2){ alert("Límite máximo sobrepasado!")}
          break;
        case 2:
          this.size_cedula = (this.files.cedula.size/Math.pow(1024,2)).toFixed(3);
          if(this.size_cedula > 2){ alert("Límite máximo sobrepasado!")}
          break;
        case 3:
          this.size_pasaporte = (this.files.pasaporte.size/Math.pow(1024,2)).toFixed(3);
          if(this.size_pasaporte > 2){ alert("Límite máximo sobrepasado!")}
          break
      }
    },
    // onSubmit(rc){
    //   // event.preventDefault();
    //   if(
    //     this.size_pdn > 2 || this.size_cedula > 2 || this.size_pasaporte > 2){
    //     alert('Alguno de los archivos suministrados supera el tamaño máximo permitido de 2 MB!')
    //   } else {
    //       rc.update = true;
    //       const formData = new FormData();
    //       formData.append('ID', JSON.stringify({id: this.$store.state.id}));
    //       formData.append('RC', JSON.stringify(rc));
    //       if(this.files.pdn){ formData.append('upFiles',this.files.pdn, 'pdn'); }
    //       if(this.files.cedula){ formData.append('upFiles',this.files.cedula, 'cedula'); }
    //       if(this.files.pasaporte){ formData.append('upFiles',this.files.pasaporte, 'pasaporte'); }
    //       fetch('/api/uploadrc/',{ method: 'POST', mode: 'cors', body: formData });
    //       this.$bvModal.show('submitted');
    //   }
    // },
    // saverc(rc){
    //   console.log('DATA:',JSON.stringify(rc));
    //   const formData = new FormData();
    //   formData.append('ID', JSON.stringify({id: this.$store.state.id}));
    //   formData.append('RC', JSON.stringify(rc));
    //   fetch('/api/uploadrc/',{ method: 'POST', mode: 'cors', body: formData });
    //   this.$bvModal.show('submitted');
    // },
    async get_registros_list(estatus){
      fetch('/api/get_registros_list',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({estatus})})
      .then(res => res.json())
      .then(registros => {
        switch(estatus){
          case 0:
            this.reg_data = registros;
            break;
          case 1:
            break;
          case 2:
            // console.log(registros)
            this.reg_tramite = registros;
            break;
        }
      }).catch(e => alert(e));
    },
    async get_buscar(event){
      event.preventDefault();
      const query = this.query;
      if(query){
        // console.log(query)
        const registros = await fetch('/api/buscar',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify(query)}).then(res => res.json());
        this.reg_buscar = registros;
        // console.log(this.reg_buscar);
        this.query = {
          field: 'p_nombre',
          qstring: undefined,
          context: '='
        }
      } else {
        alert('El campo de busqueda esta vacio!')
      }
    },
    del_rc(id,the_array,the_index){ 
      fetch('/api/del_rc', { method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({id: id, uid: this.$store.state.id})}); 
      if(the_array == 0){ this.reg_data.splice(the_index,1)} else if (the_array == 1) {this.reg_tramite.splice(the_index,1)}
    },
    // get_contacts (id){
    //   fetch('/api/get_contacts',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({id})})
    //   .then(val => {
    //     const contacts = val.json();
    //     return contacts;
    //   })
    // },

    // async process_image(mime,blob){
    //   const b64 = JSON.stringify(blob.data);
    //   // console.log(b64.toString('base64'))
    // },
    // async imgUrl(id,type){
    //     const image = await fetch('/api/get_images', { method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({id,type})}).then(res => res.json());
    //     // const intArray = new Uint8Array(image[1]);
    //     // const reducedArray = intArray.reduce((data, byte) => data + String.fromCharCode(byte), '');
    //     // const base64String = `data:${image[0]};base64, ${btoa(reducedArray)}`; 
    //     // const base64String = `data:${image[0]};base64, ${Buffer.from(image[1]).toString('base64')}`; 
    //     // return base64String;
    //     return image.name;
    // },
    update_status(persona){
      const obj_update = {
        id: persona.id,
        estatus: persona.estatus,
        p_nombre: persona.p_nombre,
        p_apellido: persona.p_apellido,
        email: persona.email
      }
      fetch('/api/update_status',{ method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({uid: this.$store.state.id, persona: obj_update})});
    },
    edit_rc(data){
      // console.log(data);
      const persona = {
        id: data.id,
        p_nombre: data.p_nombre,
        p_apellido: data.p_apellido,
        email: data.email,
        estatus: 2,
        otp: data.otp,
        msg: data.msg
      }
      fetch('/api/edit_rc', { method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({uid: this.$store.state.id,persona})});
      this.get_registros_list(0)
      this.get_registros_list(2)
    },
    async get_data(persona){
      // this.get_estados(persona.dir_pais);
        fetch('/api/get_registro', { method: 'POST', mode: 'cors', headers: {'Content-Type': 'application/json' },body: JSON.stringify({persona})})
        .then(res => res.json());

      // if(persona.pdn_mime){
      //   this.images.pdn = await this.imgUrl(persona.id,1);
      // }
      
      // if(persona.pasaporte_mime){
      //   this.images.pasaporte = await this.imgUrl(persona.id,2);
      // }
      
      // if(persona.pdn_mime){
      //   this.images.cedula = await this.imgUrl(persona.id,3);
      // }
    }
  },
  mounted(){
    this.get_registros_list(0);
    this.get_registros_list(2);
  }
}
</script>

      <!-- <template #cell(contacto)="data">
        <b-img :id="'contactos'+data.item.id" src="../assets/contacts-icon.png" width="25px" ></b-img> -->
        <!-- <b-button :id="'contactos'+data.item.id" variant="outline-info">VER</b-button> -->
        <!-- <b-tooltip :target="'contactos'+data.item.id" triggers="hover" placement="right" variant="info">
          <h8>
            {{dataitemcontacts.p_nombre}} {{dataitemcontacts.s_nombre}}<br>
            {{dataitemcontacts.p_apellido}} {{dataitemcontacts.s_apellido}}<br>
            {{dataitemcontacts.email}} {{dataitemcontacts.phone}}<br>
            {{dataitemcontacts.vinculo}}
          </h8>
        </b-tooltip>

        <b-img :id="'address'+data.item.id" src="../assets/address-icon.png" width="25px" ></b-img>
        <b-tooltip :target="'address'+data.item.id" triggers="hover" placement="right" variant="info">
          <h8>
            {{data.item.dir_calle}}<br>
            {{data.item.dir_suburbio}}<br>
            {{data.item.dir_estado}} {{data.item.dir_zipcode}}<br>
            {{data.item.dir_pais}}
          </h8>
        </b-tooltip>

      </template> -->